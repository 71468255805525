// StorageAreas.js
import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../contexts/AuthContext';
import api, { setAuthToken } from '../services/api';

function StorageAreas() {
  const { user } = useAuth();
  const [storageAreas, setStorageAreas] = useState([]);
  const [entities, setEntities] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [currentStorageArea, setCurrentStorageArea] = useState({
    name: '',
    description: '',
    entityId: '',
  });

  const isAdmin = user?.roles?.includes('admin');

  const fetchData = useCallback(async () => {
    if (!user || !user.token) return;
    
    setIsLoading(true);
    setErrorMessage('');
    try {
      setAuthToken(user.token);
      const [entitiesResponse, storageAreasResponse] = await Promise.all([
        api.get('/entities'),
        api.get('/storage-areas')
      ]);

      setEntities(entitiesResponse.data);
      
      setStorageAreas(storageAreasResponse.data.map(area => ({
        ...area,
        entity: entitiesResponse.data.find(entity => entity.id === area.entity_id) || {},
      })));
    } catch (error) {
      console.error("Error fetching data:", error);
      setErrorMessage("Failed to fetch data. Please try again.");
    } finally {
      setIsLoading(false);
    }
  }, [user]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleAddStorageArea = async () => {
    try {
      const response = await api.post('/storage-areas', {
        name: currentStorageArea.name,
        description: currentStorageArea.description,
        entity_id: currentStorageArea.entityId,
      });
      const newArea = {
        ...response.data,
        entity: entities.find(entity => entity.id === response.data.entity_id) || {},
      };
      setStorageAreas([...storageAreas, newArea]);
      setAddModalOpen(false);
    } catch (error) {
      console.error("Error adding storage area:", error);
      setErrorMessage("Failed to add storage area. Please try again.");
    }
  };

  const handleEditStorageArea = async () => {
    try {
      const response = await api.put(`/storage-areas/${currentStorageArea.id}`, {
        name: currentStorageArea.name,
        description: currentStorageArea.description,
        entity_id: currentStorageArea.entityId,
      });
      const updatedArea = {
        ...response.data,
        entity: entities.find(entity => entity.id === response.data.entity_id) || {},
      };
      setStorageAreas(storageAreas.map(area => area.id === updatedArea.id ? updatedArea : area));
      setEditModalOpen(false);
    } catch (error) {
      console.error("Error updating storage area:", error);
      setErrorMessage("Failed to update storage area. Please try again.");
    }
  };

  const handleDeleteStorageArea = async (id) => {
    try {
      await api.delete(`/storage-areas/${id}`);
      setStorageAreas(storageAreas.filter(area => area.id !== id));
    } catch (error) {
      console.error("Error deleting storage area:", error);
      setErrorMessage("Failed to delete storage area. Please try again.");
    }
  };

  const openAddModal = () => {
    setCurrentStorageArea({ name: '', description: '', entityId: '' });
    setAddModalOpen(true);
  };

  const openEditModal = (area) => {
    setCurrentStorageArea({
      id: area.id,
      name: area.name,
      description: area.description,
      entityId: area.entity_id,
    });
    setEditModalOpen(true);
  };

  return (
    <div>
      <h1 className="title">Storage Areas</h1>
      <h2 className="subtitle">Manage storage areas and their associated entities</h2>

      {isLoading && <div>Loading...</div>}
      {errorMessage && <div className="notification is-danger">{errorMessage}</div>}

      <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th className="has-text-left is-size-7">Name</th>
            <th className="has-text-left is-size-7">Description</th>
            <th className="has-text-left is-size-7">Associated Entity</th>
            {isAdmin && <th className="has-text-right is-size-7">Actions</th>}
          </tr>
        </thead>
        <tbody>
          {storageAreas.map((area) => (
            <tr key={area.id}>
              <td className="has-text-left is-size-7">{area.name}</td>
              <td className="has-text-left is-size-7">{area.description}</td>
              <td className="has-text-left is-size-7">{area.entity.name}</td>
              {isAdmin && (
                <td className="has-text-right">
                  <button className="button is-primary is-light is-small" onClick={() => openEditModal(area)}>
                    <span className="icon is-small">
                      <i className="fas fa-edit"></i>
                    </span>
                    <span>Edit</span>
                  </button>
                  <button className="button is-danger is-light is-small" onClick={() => handleDeleteStorageArea(area.id)}>
                    <span className="icon is-small">
                      <i className="fas fa-trash-alt"></i>
                    </span>
                    <span>Delete</span>
                  </button>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>

      {isAdmin && (
        <button className="button is-primary is-light is-small" onClick={openAddModal}>
          <i className="fas fa-plus"></i> Add Storage Area
        </button>
      )}

      {/* Modal pour ajouter une zone de stockage */}
      <div className={`modal ${addModalOpen ? 'is-active' : ''}`}>
        <div className="modal-background" onClick={() => setAddModalOpen(false)}></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Add Storage Area</p>
            <button className="delete" aria-label="close" onClick={() => setAddModalOpen(false)}></button>
          </header>
          <section className="modal-card-body">
            <div className="field">
              <label className="label">Name</label>
              <div className="control">
                <input 
                  className="input" 
                  type="text" 
                  value={currentStorageArea.name} 
                  onChange={(e) => setCurrentStorageArea({ ...currentStorageArea, name: e.target.value })} 
                  required 
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Description</label>
              <div className="control">
                <textarea 
                  className="textarea" 
                  value={currentStorageArea.description} 
                  onChange={(e) => setCurrentStorageArea({ ...currentStorageArea, description: e.target.value })}
                ></textarea>
              </div>
            </div>
            <div className="field">
              <label className="label">Associated Entity</label>
              <div className="control">
                <div className="select">
                  <select 
                    value={currentStorageArea.entityId} 
                    onChange={(e) => setCurrentStorageArea({ ...currentStorageArea, entityId: e.target.value })}
                    required
                  >
                    <option value="">Select an entity</option>
                    {entities.map(entity => (
                      <option key={entity.id} value={entity.id}>{entity.name}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </section>
          <footer className="modal-card-foot">
            <button className="button is-primary is-light is-small" onClick={handleAddStorageArea}>
              <span className="icon is-small">
                <i className="fas fa-check"></i>
              </span>
              <span>Submit</span>
            </button>
            <button className="button is-danger is-light is-small" onClick={() => setAddModalOpen(false)}>
              <span className="icon is-small">
                <i className="fas fa-times"></i>
              </span>
              <span>Cancel</span>
            </button>
          </footer>
        </div>
      </div>

      {/* Modal pour éditer une zone de stockage */}
      <div className={`modal ${editModalOpen ? 'is-active' : ''}`}>
        <div className="modal-background" onClick={() => setEditModalOpen(false)}></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">Edit Storage Area</p>
            <button className="delete" aria-label="close" onClick={() => setEditModalOpen(false)}></button>
          </header>
          <section className="modal-card-body">
            <div className="field">
              <label className="label">Name</label>
              <div className="control">
                <input 
                  className="input" 
                  type="text" 
                  value={currentStorageArea.name} 
                  onChange={(e) => setCurrentStorageArea({ ...currentStorageArea, name: e.target.value })} 
                  required 
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Description</label>
              <div className="control">
                <textarea 
                  className="textarea" 
                  value={currentStorageArea.description} 
                  onChange={(e) => setCurrentStorageArea({ ...currentStorageArea, description: e.target.value })}
                ></textarea>
              </div>
            </div>
            <div className="field">
              <label className="label">Associated Entity</label>
              <div className="control">
                <div className="select">
                  <select 
                    value={currentStorageArea.entityId} 
                    onChange={(e) => setCurrentStorageArea({ ...currentStorageArea, entityId: e.target.value })}
                    required
                  >
                    <option value="">Select an entity</option>
                    {entities.map(entity => (
                      <option key={entity.id} value={entity.id}>{entity.name}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </section>
          <footer className="modal-card-foot">
            <button className="button is-primary is-light is-small" onClick={handleEditStorageArea}>
              <span className="icon is-small">  
                <i className="fas fa-check"></i>
              </span>
              <span>Submit</span>
            </button>
            <button className="button is-danger is-light is-small" onClick={() => setEditModalOpen(false)}>
              <span className="icon is-small">
                <i className="fas fa-times"></i>
              </span>
              <span>Cancel</span>
            </button>
          </footer>
        </div>
      </div>
    </div>
  );
}

export default StorageAreas;