// Categories.js
import React, { useState, useEffect, useCallback } from 'react';
import api, { setAuthToken } from '../services/api';
import { useAuth } from '../contexts/AuthContext';

function Categories() {
  const { user } = useAuth();

  // États du composant
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [categoryModal, setCategoryModal] = useState({
    open: false,
    isEdit: false,
    id: null,
    name: '',
    description: '',
  });

  // Mettre à jour le token dans l'instance Axios lorsque le token change
  useEffect(() => {
    setAuthToken(user.token);
  }, [user.token]);

  // Fonction pour récupérer les catégories
  const fetchCategories = useCallback(async () => {
    setIsLoading(true);
    setErrorMessage('');
    try {
      const response = await api.get('/product-categories');
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching product categories:", error);
      setErrorMessage("Failed to fetch categories. Please try again.");
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Appeler fetchCategories au montage du composant
  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  // Fonction pour ajouter une nouvelle catégorie
  const handleAddCategory = async () => {
    try {
      await api.post('/product-categories', {
        name: categoryModal.name,
        description: categoryModal.description,
      });
      // Fermer le modal et rafraîchir les catégories
      setCategoryModal({ ...categoryModal, open: false });
      fetchCategories();
    } catch (error) {
      console.error("Error adding category:", error);
      setErrorMessage("Failed to add category. Please try again.");
    }
  };

  // Fonction pour éditer une catégorie existante
  const handleEditCategory = async () => {
    try {
      await api.put(`/product-categories/${categoryModal.id}`, {
        name: categoryModal.name,
        description: categoryModal.description,
      });
      // Fermer le modal et rafraîchir les catégories
      setCategoryModal({ ...categoryModal, open: false });
      fetchCategories();
    } catch (error) {
      console.error("Error updating category:", error);
      setErrorMessage("Failed to update category. Please try again.");
    }
  };

  // Fonction pour supprimer une catégorie
  const handleDeleteCategory = async (categoryId) => {
    try {
      await api.delete(`/product-categories/${categoryId}`);
      // Rafraîchir les catégories
      fetchCategories();
    } catch (error) {
      console.error("Error deleting category:", error);
      setErrorMessage("Failed to delete category. Please try again.");
    }
  };

  // Rendu du composant
  return (
    <div>
      <h1 className="title">Categories</h1>
      <h2 className="subtitle">Manage the categories of products</h2>

      {/* Afficher un indicateur de chargement ou un message d'erreur si nécessaire */}
      {isLoading && <div>Loading...</div>}
      {errorMessage && <div className="notification is-danger">{errorMessage}</div>}

      {/* Tableau des catégories */}
      <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th className="has-text-left is-size-7">Name</th>
            <th className="has-text-left is-size-7">Description</th>
            <th className="has-text-right is-size-7">Actions</th>
          </tr>
        </thead>
        <tbody>
          {categories.map((category) => (
            <tr key={category.id}>
              <td className="has-text-left is-size-7">{category.name}</td>
              <td className="has-text-left is-size-7">{category.description}</td>
              <td className="has-text-right">
                {/* Bouton pour éditer la catégorie */}
                <button
                  className="button is-primary is-light is-small"
                  onClick={() => setCategoryModal({ ...category, open: true, isEdit: true })}
                >
                  <span className="icon is-small">
                    <i className="fas fa-edit"></i>
                  </span>
                  <span>Edit category</span>
                </button>
                {/* Bouton pour supprimer la catégorie */}
                <button
                  className="button is-danger is-light is-small"
                  onClick={() => handleDeleteCategory(category.id)}
                >
                  <span className="icon is-small">
                    <i className="fas fa-trash-alt"></i>
                  </span>
                  <span>Delete category</span>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Bouton pour ajouter une nouvelle catégorie */}
      <button
        className="button is-primary is-light is-small"
        onClick={() => setCategoryModal({ open: true, isEdit: false, name: '', description: '' })}
      >
        <span className="icon is-small">
          <i className="fas fa-plus" aria-hidden="true"></i>
        </span>
        <span>Add category</span>
      </button>

      {/* Modal pour ajouter/éditer une catégorie */}
      <div className={`modal ${categoryModal.open ? 'is-active' : ''}`}>
        <div
          className="modal-background"
          onClick={() => setCategoryModal({ ...categoryModal, open: false })}
        ></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">
              {categoryModal.isEdit ? 'Edit Category' : 'Add Category'}
            </p>
            <button
              className="delete"
              aria-label="close"
              onClick={() => setCategoryModal({ ...categoryModal, open: false })}
            ></button>
          </header>
          <section className="modal-card-body">
            {/* Champ pour le nom de la catégorie */}
            <div className="field">
              <label className="label" htmlFor="category-name">Name:</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  id="category-name"
                  value={categoryModal.name}
                  onChange={(e) => setCategoryModal({ ...categoryModal, name: e.target.value })}
                  required
                />
              </div>
            </div>
            {/* Champ pour la description de la catégorie */}
            <div className="field">
              <label className="label" htmlFor="category-description">Description:</label>
              <div className="control">
                <textarea
                  className="textarea"
                  id="category-description"
                  value={categoryModal.description}
                  onChange={(e) => setCategoryModal({ ...categoryModal, description: e.target.value })}
                ></textarea>
              </div>
            </div>
          </section>
          <footer className="modal-card-foot">
            {/* Bouton pour ajouter ou mettre à jour la catégorie */}
            <button
              className="button is-primary is-light is-small"
              onClick={categoryModal.isEdit ? handleEditCategory : handleAddCategory}
            >
              <i className={`fas ${categoryModal.isEdit ? 'fa-edit' : 'fa-check'}`} aria-hidden="true"></i>
              <span>{categoryModal.isEdit ? 'Update' : 'Add'}</span>
            </button>
            {/* Bouton pour annuler */}
            <button
              className="button is-danger is-light is-small"
              onClick={() => setCategoryModal({ ...categoryModal, open: false })}
            >
              <i className="fas fa-times" aria-hidden="true"></i>
              <span>Cancel</span>
            </button>
          </footer>
        </div>
      </div>
    </div>
  );
}

export default Categories;
