// Planogram.js
import React from 'react';
import './Planogram.css'; // Pour les styles spécifiques si nécessaire

function Planogram({ rack, onClose }) {
  // Mapper les étagères aux positions
  const shelves = rack.shelves;

  // Fonction pour extraire la position à partir du nom de l'étagère
  const getShelfPosition = (shelfName) => {
    const parts = shelfName.split('-');
    const positionPart = parts[parts.length - 1]; // Obtenir la dernière partie (ex: A1, B4)
    const rowLetter = positionPart.charAt(0); // Lettre (A, B, C)
    const columnNumber = parseInt(positionPart.substring(1)); // Numéro (1, 2, 3, 4)

    // Convertir la lettre en numéro de ligne (A -> 1, B -> 2, etc.)
    const rowNumber = rowLetter.toUpperCase().charCodeAt(0) - 64; // 'A'.charCodeAt(0) = 65

    return {
      row: rowNumber,
      column: columnNumber,
    };
  };

  // Déterminer le nombre maximum de colonnes et de lignes
  const positions = shelves.map((shelf) => getShelfPosition(shelf.name));
  const maxRow = Math.max(...positions.map((pos) => pos.row));
  const maxColumn = Math.max(...positions.map((pos) => pos.column));

  // Créer une matrice vide pour représenter l'armoire
  const cabinet = Array.from({ length: maxRow }, () =>
    Array.from({ length: maxColumn }, () => null)
  );

  // Remplir la matrice avec les étagères
  shelves.forEach((shelf) => {
    const { row, column } = getShelfPosition(shelf.name);
    // Les étagères sont numérotées à partir du bas, nous devons inverser l'ordre des lignes
    const rowIndex = maxRow - row;
    const columnIndex = column - 1;

    cabinet[rowIndex][columnIndex] = shelf;
  });

  
return (
    <div className="modal is-active">
      <div className="modal-background" onClick={onClose}></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Planogram for Rack: {rack.name}</p>
          <button
            className="delete no-print"
            aria-label="close"
            onClick={onClose}
          ></button>
        </header>
        <section className="modal-card-body">
          <div className="cabinet">
            {cabinet.map((row, rowIndex) => (
              <div className="columns is-gapless" key={`row-${rowIndex}`}>
                {row.map((shelf, colIndex) => (
                  <div className="column" key={`col-${colIndex}`}>
                    <div className="box shelf has-text-centered">
                      {shelf ? (
                        <div>
                          <strong>{shelf.name}</strong>
                          <ul>
                            {shelf.products && shelf.products.length > 0 ? (
                              shelf.products.map((product) => (
                                <li key={product.id}>{product.description}</li>
                              ))
                            ) : (
                              <li>No products</li>
                            )}
                          </ul>
                        </div>
                      ) : (
                        <div className="empty-shelf"></div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </section>
        <footer className="modal-card-foot no-print">
          <button
            className="button is-primary no-print"
            onClick={() => window.print()}
          >
            Print
          </button>
          <button className="button no-print" onClick={onClose}>
            Close
          </button>
        </footer>
      </div>
    </div>
  );
  
}

export default Planogram;
