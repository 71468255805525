// Products.js
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useAuth } from '../contexts/AuthContext';
import api, { setAuthToken } from '../services/api';

function Products() {
  const { user } = useAuth();
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [visionModels, setVisionModels] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [editingProduct, setEditingProduct] = useState(null);
  const [countUnits, setCountUnits] = useState([]);
  const [portionUnits, setPortionUnits] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'name', direction: 'ascending' });
  const [productModal, setProductModal] = useState({
    open: false,
    isEdit: false,
    id: null,
    name: '',
    description: '',
    product_category_id: '1',
    object_classes: '',
    vision_model_id: '',
    count_unit_id: '',
    portion_unit_id: '',
    portion_per_count: '',
  });
  const openAddProductModal = () => {
    setProductModal({
      open: true,
      isEdit: false,
      name: '',
      description: '',
      product_category_id: '',
      object_classes: '',
      vision_model_id: '',
      count_unit_id: '',
      portion_unit_id: '',
      portion_per_count: '',
    });
  };

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedProducts = useMemo(() => {
    let sortableProducts = [...products];
    if (sortConfig !== null) {
      sortableProducts.sort((a, b) => {
        if (sortConfig.key === 'product_category') {
          return getCategoryName(a.product_category_id).localeCompare(getCategoryName(b.product_category_id));
        }
        if (sortConfig.key === 'vision_model') {
          return (a.vision_model?.name || '').localeCompare(b.vision_model?.name || '');
        }
        if (sortConfig.key === 'count_unit') {
          return (a.count_unit?.name || '').localeCompare(b.count_unit?.name || '');
        }
        if (sortConfig.key === 'portion_unit') {
          return (a.portion_unit?.name || '').localeCompare(b.portion_unit?.name || '');
        }
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableProducts;
  }, [products, sortConfig]);

  const SortableHeader = ({ column, label }) => (
    <th 
      className="has-text-left is-size-7" 
      onClick={() => requestSort(column)}
      style={{ cursor: 'pointer' }}
    >
      {label}
      {sortConfig.key === column && (
        <span className="icon is-small ml-1">
          <i className={`fas fa-sort-${sortConfig.direction === 'ascending' ? 'up' : 'down'}`}></i>
        </span>
      )}
    </th>
  );
  

  const fetchProducts = useCallback(async () => {
    setIsLoading(true);
    setErrorMessage('');
    try {
      const response = await api.get('/products');
      setProducts(response.data);
    } catch (error) {
      console.error("Error fetching products:", error);
      setErrorMessage("Failed to fetch products. Please try again.");
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchCategories = useCallback(async () => {
    try {
      const response = await api.get('/product-categories');
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching product categories:", error);
    }
  }, []);

  const fetchVisionModels = useCallback(async () => {
    try {
      const response = await api.get('/vision-models');
      setVisionModels(response.data);
    } catch (error) {
      console.error("Error fetching vision models:", error);
    }
  }, []);

  const fetchCountUnits = useCallback(async () => {
    try {
      const response = await api.get('/count-units');
      setCountUnits(response.data);
    } catch (error) {
      console.error("Error fetching count units:", error);
    }
  }, []);

  const fetchPortionUnits = useCallback(async () => {
    try {
      const response = await api.get('/portion-units');
      setPortionUnits(response.data);
    } catch (error) {
      console.error("Error fetching portion units:", error);
    }
  }, []);

  const handleProductChange = async (productId, field, value) => {
    try {
      const updatedProduct = { ...products.find(p => p.id === productId), [field]: value };
      await api.put(`/products/${productId}`, updatedProduct);
      fetchProducts(); // Rafraîchir la liste des produits
    } catch (error) {
      console.error(`Error updating product ${productId}:`, error);
      setErrorMessage("Failed to update product. Please try again.");
    }
  };

  useEffect(() => {
    if (user && user.token) {
      setAuthToken(user.token);
      fetchProducts();
      fetchCategories();
      fetchVisionModels();
      fetchCountUnits();
      fetchPortionUnits();
    } else {
      setAuthToken(null);
    }
  }, [user, fetchProducts, fetchCategories, fetchVisionModels, fetchCountUnits, fetchPortionUnits]);

  const getCategoryName = (categoryId) => {
    const category = categories.find((c) => c.id === categoryId);
    return category ? category.name : "";
  };

  const handleAddProduct = async () => {
    try {
      const productData = {
        name: productModal.name,
        description: productModal.description,
        product_category_id: productModal.product_category_id ? parseInt(productModal.product_category_id) : null,
        object_classes: productModal.object_classes ? parseInt(productModal.object_classes) : null,
        vision_model_id: productModal.vision_model_id ? parseInt(productModal.vision_model_id) : null,
        count_unit_id: productModal.count_unit_id ? parseInt(productModal.count_unit_id) : null,
        portion_unit_id: productModal.portion_unit_id ? parseInt(productModal.portion_unit_id) : null,
        portion_per_count: productModal.portion_per_count ? parseFloat(productModal.portion_per_count) : null,
      };
  
      // Filtrer les champs null
      const filteredProductData = Object.fromEntries(
        Object.entries(productData).filter(([_, v]) => v != null)
      );
  
      await api.post('/products', productData);
      setProductModal({ ...productModal, open: false });
      fetchProducts();
    } catch (error) {
      console.error("Error adding product:", error);
      setErrorMessage("Failed to add product. Please try again.");
    }
  };

  const handleEditProduct = async () => {
    try {
      await api.put(`/products/${productModal.id}`, productModal);
      setProductModal({ ...productModal, open: false });
      fetchProducts();
    } catch (error) {
      console.error("Error updating product:", error);
      setErrorMessage("Failed to update product. Please try again.");
    }
  };

  const handleDeleteProduct = async (productId) => {
    try {
      await api.delete(`/products/${productId}`);
      fetchProducts();
    } catch (error) {
      console.error("Error deleting product:", error);
      setErrorMessage("Failed to delete product. Please try again.");
    }
  };

  return (
    <div>
      <h1 className="title">Products</h1>
      <h2 className="subtitle">Manage the products</h2>

      {isLoading && <div>Loading...</div>}
      {errorMessage && <div className="notification is-danger">{errorMessage}</div>}

      <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
        <thead>
          <tr>
            <SortableHeader column="object_classes" label="Class Model" />
            <SortableHeader column="name" label="Name" />
            <SortableHeader column="description" label="Description" />
            <SortableHeader column="product_category" label="Category" />
            <SortableHeader column="count_unit" label="Count Unit" />
            <SortableHeader column="portion_unit" label="Portion Unit" />
            <SortableHeader column="portion_per_count" label="Portion Per Count" />
            <SortableHeader column="vision_model" label="Vision Model" />
            <th className="has-text-right is-size-7">Actions</th>
          </tr>
        </thead>
        <tbody>
          {sortedProducts.map((product) => (
            <tr key={product.id}>

              <td className="has-text-left is-size-7">
                <input
                  className="input is-small"
                  type="number"
                  value={product.object_classes}
                  onChange={(e) => handleProductChange(product.id, 'object_classes', e.target.value)}
                />
              </td>
              
              <td className="has-text-left is-size-7">
                {editingProduct === product.id ? (
                  <input
                    className="input is-small"
                    type="text"
                    value={product.name}
                    onChange={(e) => handleProductChange(product.id, 'name', e.target.value)}
                    onBlur={() => setEditingProduct(null)}
                  />
                ) : (
                  <span onClick={() => setEditingProduct(product.id)}>{product.name}</span>
                )}
              </td>

              <td className="has-text-left is-size-7">
                {editingProduct === product.id ? (
                  <textarea
                    className="textarea is-small"
                    value={product.description}
                    onChange={(e) => handleProductChange(product.id, 'description', e.target.value)}
                    onBlur={() => setEditingProduct(null)}
                  />
                ) : (
                  <span onClick={() => setEditingProduct(product.id)}>{product.description}</span>
                )}
              </td>

              <td className="has-text-left is-size-7">
                <div className="select is-small">
                  <select
                    value={product.product_category_id}
                    onChange={(e) => handleProductChange(product.id, 'product_category_id', e.target.value)}
                  >
                    {categories.map((category) => (
                      <option key={category.id} value={category.id}>{category.name}</option>
                    ))}
                  </select>
                </div>
              </td>

              <td className="has-text-left is-size-7">
                <div className="select is-small">
                  <select
                    value={product.count_unit_id || ''}
                    onChange={(e) => handleProductChange(product.id, 'count_unit_id', e.target.value)}
                  >
                    <option value="">Select a count unit</option>
                    {countUnits.map((unit) => (
                      <option key={unit.id} value={unit.id}>{unit.name}</option>
                    ))}
                  </select>
                </div>
              </td>

              <td className="has-text-left is-size-7">
                <div className="select is-small">
                  <select
                    value={product.portion_unit_id || ''}
                    onChange={(e) => handleProductChange(product.id, 'portion_unit_id', e.target.value)}
                  >
                    <option value="">Select a portion unit</option>
                    {portionUnits.map((unit) => (
                      <option key={unit.id} value={unit.id}>{unit.name}</option>
                    ))}
                  </select>
                </div>
              </td>

              <td className="has-text-left is-size-7">
                <input
                  className="input is-small"
                  type="number"
                  value={product.portion_per_count || ''}
                  onChange={(e) => handleProductChange(product.id, 'portion_per_count', e.target.value)}
                />
              </td>

              <td className="has-text-left is-size-7">
                <div className="select is-small">
                  <select
                    value={product.vision_model_id || ''}
                    onChange={(e) => handleProductChange(product.id, 'vision_model_id', e.target.value)}
                  >
                    <option value="">Select a vision model</option>
                    {visionModels.map((model) => (
                      <option key={model.id} value={model.id}>{model.name}</option>
                    ))}
                  </select>
                </div>
              </td>

              <td className="has-text-right">

              <div className="field is-grouped">
              <p className="control">
                  <button className="button is-primary is-light is-small is-responsive" onClick={() => setProductModal({ ...product, open: true, isEdit: true })}>
                    <span className="icon is-small">
                      <i className="fas fa-edit"></i>
                    </span>
                    <span>Edit</span>
                  </button>
                  <button className="button is-danger is-light is-small is-responsive" onClick={() => handleDeleteProduct(product.id)}>
                    <span className="icon is-small">
                      <i className="fas fa-trash-alt"></i>
                    </span>
                    <span>Delete</span>
                  </button>
                  </p>
                </div>

              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <button className="button is-primary is-light is-small" onClick={() => setProductModal({ open: true, isEdit: false, name: '', description: '', product_category_id: '', object_classes: '', vision_model_id: '' })}>
        <i className="fas fa-plus"></i> Add Product
      </button>

      {/* Modal pour ajouter/éditer un produit */}
      <div className={`modal ${productModal.open ? 'is-active' : ''}`}>
        <div className="modal-background" onClick={() => setProductModal({ ...productModal, open: false })}></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">{productModal.isEdit ? 'Edit Product' : 'Add Product'}</p>
            <button className="delete" aria-label="close" onClick={() => setProductModal({ ...productModal, open: false })}></button>
          </header>
          <section className="modal-card-body">
            <div className="field">
              <label className="label">Name</label>
              <div className="control">
                <input className="input" type="text" value={productModal.name} onChange={(e) => setProductModal({ ...productModal, name: e.target.value })} required />
              </div>
            </div>
            <div className="field">
              <label className="label">Description</label>
              <div className="control">
                <textarea className="textarea" value={productModal.description} onChange={(e) => setProductModal({ ...productModal, description: e.target.value })}></textarea>
              </div>
            </div>
            <div className="field">
              <label className="label">Category</label>
              <div className="control">
                <div className="select">
                <select 
                  value={productModal.product_category_id} 
                  onChange={(e) => setProductModal({ ...productModal, product_category_id: e.target.value })}
                >
                  <option value="" disabled>Select a category</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>{category.name}</option>
                  ))}
                </select>
                </div>
              </div>
            </div>

            <div className="field">
              <label className="label">Count Unit</label>
              <div className="control">
                <div className="select">
                <select 
                  value={productModal.count_unit_id} 
                  onChange={(e) => setProductModal({ ...productModal, count_unit_id: e.target.value })}
                >
                  <option value="">Select a count unit</option>
                  {countUnits.map((unit) => (
                    <option key={unit.id} value={unit.id}>{unit.name}</option>
                  ))}
                </select>
                </div>
              </div>
            </div>

            <div className="field">
              <label className="label">Portion Unit</label>
              <div className="control">
                <div className="select">
                <select 
                  value={productModal.portion_unit_id} 
                  onChange={(e) => setProductModal({ ...productModal, portion_unit_id: e.target.value })}
                >
                  <option value="">Select a portion unit</option>
                  {portionUnits.map((unit) => (
                    <option key={unit.id} value={unit.id}>{unit.name}</option>
                  ))}
                </select>
                </div>
              </div>
            </div>

            <div className="field">
              <label className="label">Portion Per Count</label>
              <div className="control">
                <input className="input" type="number" value={productModal.portion_per_count} onChange={(e) => setProductModal({ ...productModal, portion_per_count: e.target.value })} />
              </div>
            </div>

            <div className="field">
              <label className="label">Object Classes</label>
              <div className="control">
                <input className="input" type="text" value={productModal.object_classes} onChange={(e) => setProductModal({ ...productModal, object_classes: e.target.value })} />
              </div>
            </div>
            <div className="field">
              <label className="label">Vision Model</label>
              <div className="control">
                <div className="select">
                  <select value={productModal.vision_model_id} onChange={(e) => setProductModal({ ...productModal, vision_model_id: e.target.value })}>
                    <option value="">Select a vision model</option>
                    {visionModels.map((model) => (
                      <option key={model.id} value={model.id}>{model.name}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </section>
          <footer className="modal-card-foot">
            <button className="button is-primary is-light is-small is-responsive" onClick={productModal.isEdit ? handleEditProduct : handleAddProduct}>Submit</button>
            <button className="button is-danger is-light is-small is-responsive" onClick={() => setProductModal({ ...productModal, open: false })}>Cancel</button>
          </footer>
        </div>
      </div>
    </div>
  );
}

export default Products;