// api.js
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

// Créer une instance Axios avec le baseURL
const api = axios.create({
  baseURL: API_URL,
});

// Fonction pour mettre à jour le token dans l'instance Axios
export const setAuthToken = (token) => {
  if (token) {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete api.defaults.headers.common['Authorization'];
  }
};

// Exemples de fonctions d'API
export const fetchUsers = () => api.get('/users');
export const fetchEntities = () => api.get('/entities');
export const fetchStorageAreas = () => api.get('/storage-areas');

// Ajoutez d'autres fonctions d'API au besoin

export default api;
