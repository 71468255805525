import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useAuth } from '../contexts/AuthContext';
import api from '../services/api';

function Stock() {
  // =========================================================
  // State Management
  // =========================================================
  const { user } = useAuth();
  const [stocks, setStocks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [expandedProducts, setExpandedProducts] = useState(new Set());
  
  // Sorting state
  const [sortConfig, setSortConfig] = useState({
    key: 'product_name',
    direction: 'asc'
  });

  // Filter states
  const [filters, setFilters] = useState({
    product: '',
    location: '',
  });

  // Edit modal state
  const [editModal, setEditModal] = useState({
    isOpen: false,
    stockId: null,
    quantity: 0,
    originalQuantity: 0,
    location: null
  });

  // Pagination state
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  // Refs and constants
  const printRef = useRef();
  const isAdmin = user?.roles?.includes('admin');

  // =========================================================
  // Export Functions
  // =========================================================
  const handleExportCSV = useCallback(() => {
    if (!user?.token) {
      setErrorMessage("Authentication required for export");
      return;
    }
    window.open(`${api.defaults.baseURL}/stocks/export/csv?token=${user.token}`, '_blank');
  }, [user?.token]);

  const handleExportExcel = useCallback(() => {
    if (!user?.token) {
      setErrorMessage("Authentication required for export");
      return;
    }
    window.open(`${api.defaults.baseURL}/stocks/export/excel?token=${user.token}`, '_blank');
  }, [user?.token]);

  // =========================================================
  // Data Fetching
  // =========================================================
  const fetchStocks = useCallback(async () => {
    setIsLoading(true);
    setErrorMessage('');
    try {
      if (!user?.token) {
        throw new Error('No authentication token available');
      }

      const response = await api.get('/stocks', {
        headers: { 'Authorization': `Bearer ${user.token}` }
      });
      
      // Group stocks by product for better organization
      const groupedStocks = response.data.stocks.reduce((acc, stock) => {
        const key = stock.product_name;
        if (!acc[key]) {
          acc[key] = {
            product_name: stock.product_name,
            product_description: stock.product_description,
            total_quantity: 0,
            locations: [],
            updated_at: stock.updated_at
          };
        }
        
        acc[key].total_quantity += stock.quantity;
        acc[key].locations.push({
          stock_id: stock.stock_id,
          entity_name: stock.entity_name,
          storage_area_name: stock.storage_area_name,
          rack_name: stock.rack_name,
          shelf_name: stock.shelf_name,
          quantity: stock.quantity,
          updated_at: stock.updated_at,
          updated_by: stock.updated_by
        });
        
        // Keep most recent update date
        if (new Date(stock.updated_at) > new Date(acc[key].updated_at)) {
          acc[key].updated_at = stock.updated_at;
        }
        
        return acc;
      }, {});

      setStocks(Object.values(groupedStocks));
    } catch (error) {
      console.error("Error fetching stocks:", error);
      setErrorMessage(error.response?.status === 401
        ? "Session expired. Please log in again."
        : "Failed to fetch stocks. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  }, [user]);

  // Initial data fetch
  useEffect(() => {
    fetchStocks();
  }, [fetchStocks]);

  // =========================================================
  // Event Handlers
  // =========================================================
  const handleDelete = async (stockId) => {
    if (window.confirm('Are you sure you want to delete this stock item?')) {
      try {
        await api.delete(`/stock/${stockId}`, {
          headers: { 'Authorization': `Bearer ${user.token}` }
        });
        fetchStocks();
      } catch (error) {
        setErrorMessage("Error deleting stock item");
      }
    }
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    try {
      // Récupérer toutes les informations du stock actuel
      const currentLocation = stocks
        .flatMap(product => product.locations)
        .find(location => location.stock_id === editModal.stockId);
  
      if (!currentLocation) {
        throw new Error("Stock location not found");
      }
  
      // Préparer les données selon le format attendu par le backend
      const updateData = {
        entity_id: currentLocation.entity_id,
        storage_area_id: currentLocation.storage_area_id,
        rack_id: currentLocation.rack_id,
        shelf_id: currentLocation.shelf_id,
        product_id: currentLocation.product_id,
        quantity: editModal.quantity,
        updated_by: user.id
      };
  
      await api.put(`/stock/${editModal.stockId}`, updateData, {
        headers: { 'Authorization': `Bearer ${user.token}` }
      });
  
      setEditModal({ isOpen: false, stockId: null, quantity: 0, originalQuantity: 0 });
      fetchStocks(); // Rafraîchir les données
    } catch (error) {
      console.error("Error updating stock:", error);
      setErrorMessage("Error updating stock quantity: " + (error.message || 'Unknown error'));
    }
  };

  const handlePrint = useCallback(() => {
    window.print();
  }, []);

  // =========================================================
  // UI Interaction Handlers
  // =========================================================
  const toggleProductExpand = useCallback((productName) => {
    setExpandedProducts(prev => {
      const newSet = new Set(prev);
      if (newSet.has(productName)) {
        newSet.delete(productName);
      } else {
        newSet.add(productName);
      }
      return newSet;
    });
  }, []);

  const handleSort = useCallback((key) => {
    setSortConfig(current => ({
      key,
      direction: current.key === key && current.direction === 'asc' ? 'desc' : 'asc'
    }));
  }, []);

  // =========================================================
  // Data Processing Functions
  // =========================================================
  const filterStocks = useCallback((stocks) => {
    return stocks.filter(stock => {
      const matchProduct = (stock.product_name + ' ' + (stock.product_description || '')).toLowerCase()
        .includes(filters.product.toLowerCase());
      
      const matchLocation = stock.locations.some(loc => 
        `${loc.storage_area_name} ${loc.rack_name} ${loc.shelf_name}`.toLowerCase()
          .includes(filters.location.toLowerCase())
      );

      return matchProduct && (filters.location === '' || matchLocation);
    });
  }, [filters]);

  const sortData = useCallback((data) => {
    return [...data].sort((a, b) => {
      let aValue, bValue;
      
      switch (sortConfig.key) {
        case 'product_name':
          aValue = a.product_name.toLowerCase();
          bValue = b.product_name.toLowerCase();
          break;
        case 'total_quantity':
          aValue = a.total_quantity;
          bValue = b.total_quantity;
          break;
        case 'updated_at':
          aValue = new Date(a.updated_at);
          bValue = new Date(b.updated_at);
          break;
        default:
          return 0;
      }

      if (sortConfig.direction === 'asc') {
        return aValue > bValue ? 1 : -1;
      } else {
        return aValue < bValue ? 1 : -1;
      }
    });
  }, [sortConfig]);

  // =========================================================
  // UI Helper Functions
  // =========================================================
  const renderSortIcon = useCallback((columnKey) => (
    <span className="ml-2">
      <i className={`fas fa-sort${
        sortConfig.key === columnKey 
          ? sortConfig.direction === 'asc'
            ? '-up'
            : '-down'
          : ''
      }`}></i>
    </span>
  ), [sortConfig]);

  // =========================================================
  // Render Components
  // =========================================================
  const renderFilterBar = () => (
    <div className="columns mb-4">
      <div className="column is-4">
        <input
          className="input is-small"
          type="text"
          placeholder="Search by product name..."
          value={filters.product}
          onChange={(e) => setFilters({...filters, product: e.target.value})}
        />
      </div>
      <div className="column is-4">
        <input
          className="input is-small"
          type="text"
          placeholder="Search by location..."
          value={filters.location}
          onChange={(e) => setFilters({...filters, location: e.target.value})}
        />
      </div>
      <div className="column is-4">
        <div className="select is-small is-fullwidth">
          <select 
            value={itemsPerPage}
            onChange={(e) => {
              setItemsPerPage(Number(e.target.value));
              setCurrentPage(1);
            }}
          >
            <option value={10}>10 per page</option>
            <option value={25}>25 per page</option>
            <option value={50}>50 per page</option>
            <option value={100}>100 per page</option>
          </select>
        </div>
      </div>
    </div>
  );

  const renderEditModal = () => (
    <div className={`modal ${editModal.isOpen ? 'is-active' : ''}`}>
      <div className="modal-background" onClick={() => setEditModal(prev => ({ ...prev, isOpen: false }))}></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Edit Stock Quantity</p>
          <button 
            className="delete" 
            aria-label="close" 
            onClick={() => setEditModal(prev => ({ ...prev, isOpen: false }))}
          ></button>
        </header>
        <section className="modal-card-body">
          <form onSubmit={handleEdit}>
            <div className="field">
              <label className="label">New Quantity</label>
              <div className="control">
                <input
                  className="input"
                  type="number"
                  value={editModal.quantity}
                  onChange={(e) => setEditModal(prev => ({
                    ...prev,
                    quantity: parseInt(e.target.value) || 0
                  }))}
                  min="0"
                />
              </div>
            </div>
          </form>
        </section>
        <footer className="modal-card-foot">
          <button className="button is-success" onClick={handleEdit}>Save Changes</button>
          <button className="button" onClick={() => setEditModal(prev => ({ ...prev, isOpen: false }))}>Cancel</button>
        </footer>
      </div>
    </div>
  );

  const renderProductRow = useCallback((product) => {
    const isExpanded = expandedProducts.has(product.product_name);
    
    return (
      <React.Fragment key={product.product_name}>
        <tr className="product-row">
          <td className="has-text-centered">
            <button 
              className="button is-small is-white"
              onClick={() => toggleProductExpand(product.product_name)}
            >
              <span className="icon">
                <i className={`fas fa-${isExpanded ? 'minus' : 'plus'}`}></i>
              </span>
            </button>
          </td>
          <td>
            <div className="py-2">
              <p className="is-size-7 has-text-weight-medium mb-1 cursor-pointer" 
                 onClick={() => handleSort('product_name')}>
                {product.product_name}
              </p>
              <p className="is-size-7 has-text-grey">
                {product.product_description || 'No description available'}
              </p>
            </div>
          </td>
          <td className="has-text-right is-size-7 cursor-pointer"
              onClick={() => handleSort('total_quantity')}>
            {product.total_quantity}
          </td>
          <td className="is-size-7 cursor-pointer"
              onClick={() => handleSort('updated_at')}>
            {new Date(product.updated_at).toLocaleString()}
          </td>
        </tr>
        {isExpanded && (
          <tr className="details-row has-background-light">
            <td colSpan="4">
              <table className="table is-fullwidth is-narrow is-bordered">
                <thead>
                  <tr>
                    <th className="is-size-7">Location</th>
                    <th className="is-size-7 has-text-right">Quantity</th>
                    <th className="is-size-7">Last Updated</th>
                    <th className="is-size-7">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {product.locations.map((location) => (
                    <tr key={location.stock_id} className="is-size-7">
                      <td>
                        {location.storage_area_name} / {location.rack_name} / {location.shelf_name}
                      </td>
                      <td className="has-text-right">{location.quantity}</td>
                      <td>{new Date(location.updated_at).toLocaleString()}</td>
                      <td>
                        <div className="buttons are-small">
                        <button 
                          className="button is-info is-light"
                          onClick={() => setEditModal({
                            isOpen: true,
                            stockId: location.stock_id,
                            quantity: location.quantity,
                            originalQuantity: location.quantity,
                            location: location  // Stocker toutes les informations de location
                          })}
                        >
                            <span className="icon is-small">
                              <i className="fas fa-edit"></i>
                            </span>
                          </button>
                          <button 
                            className="button is-danger is-light"
                            onClick={() => handleDelete(location.stock_id)}
                          >
                            <span className="icon is-small">
                              <i className="fas fa-trash"></i>
                            </span>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </td>
          </tr>
        )}
      </React.Fragment>
    );
  }, [expandedProducts, handleDelete, handleSort, toggleProductExpand]);

  const renderStockTable = () => {
    const filteredStocks = filterStocks(stocks);
    const sortedStocks = sortData(filteredStocks);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedStocks = sortedStocks.slice(startIndex, endIndex);

    return (
      <div className="table-container" id="printable-content">
        <div className="is-flex is-justify-content-space-between mb-4">
          <p className="has-text-weight-bold">
            Total Products: {filteredStocks.length}
          </p>
          <div className="buttons are-small">
            <button 
              className="button is-light"
              onClick={handlePrint}
            >
              <span className="icon">
                <i className="fas fa-print"></i>
              </span>
              <span>Print</span>
            </button>
          </div>
        </div>
        
        <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
          <thead>
            <tr>
              <th width="40"></th>
              <th 
                className="is-size-7 cursor-pointer"
                onClick={() => handleSort('product_name')}
              >
                Product Name {renderSortIcon('product_name')}
              </th>
              <th 
                className="is-size-7 has-text-right cursor-pointer"
                onClick={() => handleSort('total_quantity')}
              >
                Total Quantity {renderSortIcon('total_quantity')}
              </th>
              <th 
                className="is-size-7 cursor-pointer"
                onClick={() => handleSort('updated_at')}
              >
                Last Updated {renderSortIcon('updated_at')}
              </th>
            </tr>
          </thead>
          <tbody>
            {paginatedStocks.map(renderProductRow)}
          </tbody>
        </table>
      </div>
    );
  };

  // =========================================================
  // Main Render
  // =========================================================
  return (
    <div className="px-4">
      <h1 className="title">Stock Management</h1>
      <h2 className="subtitle">Inventory Overview</h2>

      <div className="buttons are-small mb-4">
        <button 
          className="button is-info is-light" 
          onClick={fetchStocks} 
          disabled={isLoading}
        >
          <span className="icon">
            <i className="fas fa-sync-alt"></i>
          </span>
          <span>Refresh</span>
        </button>

        <button 
          className="button is-primary is-light"
          onClick={handleExportCSV}
        >
          <span className="icon">
            <i className="fas fa-file-csv"></i>
          </span>
          <span>Export CSV</span>
        </button>

        <button 
          className="button is-primary is-light"
          onClick={handleExportExcel}
        >
          <span className="icon">
            <i className="fas fa-file-excel"></i>
          </span>
          <span>Export Excel</span>
        </button>
      </div>

      {isLoading && (
        <div className="is-flex is-justify-content-center is-align-items-center py-4">
          <span className="icon">
            <i className="fas fa-spinner fa-spin"></i>
          </span>
          <span className="ml-2">Loading...</span>
        </div>
      )}

      {errorMessage && (
        <div className="notification is-danger is-light">
          <button className="delete" onClick={() => setErrorMessage('')}></button>
          {errorMessage}
        </div>
      )}

      {renderFilterBar()}
      {renderStockTable()}
      {renderEditModal()}

      <nav className="pagination is-small is-centered my-4" role="navigation" aria-label="pagination">
        <button
          className="pagination-previous"
          onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <button
          className="pagination-next"
          onClick={() => {
            const filteredStocks = filterStocks(stocks);
            const totalPages = Math.ceil(filteredStocks.length / itemsPerPage);
            setCurrentPage(prev => Math.min(prev + 1, totalPages));
          }}
          disabled={currentPage === Math.ceil(filterStocks(stocks).length / itemsPerPage)}
        >
          Next
        </button>
        <ul className="pagination-list">
          {Array.from({ length: Math.ceil(filterStocks(stocks).length / itemsPerPage) }, (_, i) => (
            <li key={i + 1}>
              <button
                className={`pagination-link ${currentPage === i + 1 ? 'is-current' : ''}`}
                aria-label={`Go to page ${i + 1}`}
                onClick={() => setCurrentPage(i + 1)}
              >
                {i + 1}
              </button>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
}

export default Stock;