// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { initKeycloak, getToken, logout as keycloakLogout } from '../services/auth';
import { jwtDecode } from 'jwt-decode';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    initKeycloak().then((authenticated) => {
      if (authenticated) {
        const token = getToken();
        console.log('JWT Token:', token);
        const userData = jwtDecode(token);
        console.log('User Data:', userData);
        if (userData) {
          setUser({
            name: userData.name || userData.preferred_username,
            email: userData.email,
            token: token,
            roles: userData.realm_access?.roles || [] // Ajout des rôles de l'utilisateur
          });
          setAuthenticated(true);
          localStorage.setItem('token', token); // Stocker le token dans le localStorage
          console.log('User Data:', userData);
        }
      }
      setLoading(false);
    });
  }, []);

  const logout = async () => {
    try {
      await keycloakLogout();
      setUser(null);
      setAuthenticated(false);
      localStorage.removeItem('token'); // Supprimer le token du localStorage lors de la déconnexion
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  if (loading) {
    return <div>Loading auth context...</div>;
  }

  return (
    <AuthContext.Provider value={{ user, setUser, authenticated, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);